var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn0 btn-secondary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"}),_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto d-xl-flex d-lg-flex d-md-flex d-none"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'appointments-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer un nouveau produit ")])]),_c('div',{staticClass:"col-auto d-xl-flex d-lg-flex d-md-flex d-none"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.printAll()}}},[_c('i',{staticClass:"bi bi-printer"}),_vm._v(" Imprimer ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover d-xl-table d-lg-table d-md-table d-none"},[_vm._m(0),_c('tbody',_vm._l((_vm.appointments),function(appointment,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(appointment.reference)+" ")]),_c('td',[(appointment.employee)?_c('span',[_vm._v(" "+_vm._s(appointment.employee.fullName)+" ")]):_vm._e()]),_c('td',[(appointment.customer)?_c('span',[_vm._v(" "+_vm._s(appointment.customer.fullName)+" ")]):_vm._e()]),_c('td',[(appointment.customer)?_c('span',[_vm._v(" "+_vm._s(appointment.customer.city)+" ")]):_vm._e()]),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'repairs-details',
              params: {
                reference: appointment.repair_reference,
              },
            }}},[_vm._v(" "+_vm._s(appointment.repair_reference)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(appointment.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(appointment.time)+" ")]),_c('td',[(appointment.validated == true)?_c('span',{staticClass:"text-success"},[_vm._v(" Validé ")]):_c('span',{staticClass:"text-warning"},[_vm._v(" Pas validé ")])]),_c('td',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'appointments-details',
                params: {
                  reference: appointment.reference,
                },
              })}}},[_c('i',{staticClass:"bi bi-plus"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'appointments-edit',
                params: {
                  reference: appointment.reference,
                },
              })}}},[_c('i',{staticClass:"bi bi-pencil-square"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(appointment)}}},[_c('i',{staticClass:"bi bi-trash text-danger"})])])])}),0)]),_c('table',{staticClass:"table table-striped table-hover d-xl-none d-lg-none d-md-none d-table"},[_vm._m(1),_c('tbody',_vm._l((_vm.appointments),function(appointment,index){return _c('tr',{key:index++,class:appointment.validated == true ? ' text-success' : ''},[_c('td',[(appointment.customer)?_c('span',[_vm._v(" "+_vm._s(appointment.customer.fullName)+" ")]):_vm._e()]),_c('td',[(appointment.customer)?_c('span',[_vm._v(" "+_vm._s(appointment.customer.city)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(appointment.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(appointment.time)+" ")]),_c('td',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'appointments-details',
                params: {
                  reference: appointment.reference,
                },
              })}}},[_c('i',{staticClass:"bi bi-eye-fill"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Employee")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Client")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ville")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Reparation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Client")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ville")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Time")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }