<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto d-xl-flex d-lg-flex d-md-flex d-none">
        <button
          @click="$router.push({ name: 'appointments-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau produit
        </button>
      </div>

      <div class="col-auto d-xl-flex d-lg-flex d-md-flex d-none">
        <button @click="printAll()" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button>
      </div>
    </div>
    <br />
    <table
      class="table table-striped table-hover d-xl-table d-lg-table d-md-table d-none"
    >
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Employee</th>
          <th scope="col">Client</th>
          <th scope="col">Ville</th>
          <th scope="col">Reparation</th>
          <th scope="col">Date</th>
          <th scope="col">Time</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(appointment, index) in appointments" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ appointment.reference }}
          </td>

          <td>
            <span v-if="appointment.employee">
              {{ appointment.employee.fullName }}
            </span>
          </td>

          <td>
            <span v-if="appointment.customer">
              {{ appointment.customer.fullName }}
            </span>
          </td>

          <td>
            <span v-if="appointment.customer">
              {{ appointment.customer.city }}
            </span>
          </td>

          <td>
            <router-link
              :to="{
                name: 'repairs-details',
                params: {
                  reference: appointment.repair_reference,
                },
              }"
            >
              {{ appointment.repair_reference }}
            </router-link>
          </td>

          <td>
            {{ appointment.date }}
          </td>

          <td>
            {{ appointment.time }}
          </td>

          <td>
            <span v-if="appointment.validated == true" class="text-success">
              Validé
            </span>

            <span v-else class="text-warning"> Pas validé </span>
          </td>

          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'appointments-details',
                  params: {
                    reference: appointment.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-plus"></i>
            </button>
            <button
              @click="
                $router.push({
                  name: 'appointments-edit',
                  params: {
                    reference: appointment.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button @click="remove(appointment)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <table
      class="table table-striped table-hover d-xl-none d-lg-none d-md-none d-table"
    >
      <thead>
        <tr>
          <th scope="col">Client</th>
          <th scope="col">Ville</th>
          <th scope="col">Date</th>
          <th scope="col">Time</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(appointment, index) in appointments"
          :key="index++"
          :class="appointment.validated == true ? ' text-success' : ''"
        >
          <td>
            <span v-if="appointment.customer">
              {{ appointment.customer.fullName }}
            </span>
          </td>

          <td>
            <span v-if="appointment.customer">
              {{ appointment.customer.city }}
            </span>
          </td>

          <td>
            {{ appointment.date }}
          </td>

          <td>
            {{ appointment.time }}
          </td>

          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'appointments-details',
                  params: {
                    reference: appointment.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-eye-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("appointment", {
      appointments: "getAppointments",
    }),
    // imageUrl() {
    //   return (appointment) => `${appointment.image}`
    // },
  },
  mounted() {
    this.$store.dispatch("appointment/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("appointment/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("appointment/getAll");
    },

    async sort(value) {
      return await this.$store.commit("appointment/sort", value);
    },

    async remove(data) {
      let appointment = data;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + appointment.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("appointment/destroy", appointment.reference);
          }
        },
      });
    },

    async printAll() {
      return await this.$store.dispatch("appointment/printAll");
    },
  },
};
</script>
